import React, { useEffect } from 'react';
import { logoutWithRedirect } from '../utils/authHelper';
import PropTypes from 'prop-types';
import { getAccessTokenSilently, getIdTokenSilently } from '../utils/authHelper';
const UserContext = React.createContext({});

const initialState = {
  isAuthenticated: false,
  token: null,
  loadingToken: true
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'loginUser':
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        token: action.payload.token,
        loadingToken: action.payload.loadingToken
      };

    case 'logoutUser':
      return initialState;
    default:
      return state;
  }
};

const handleSignIn = async (dispatch) => {
  try {
    dispatch({
      type: 'loginUser',
      payload: {
        isAuthenticated: true,
        loadingToken: false
      }
    });
    // }
  } catch (e) {
    dispatch({
      type: 'loginUser',
      payload: {
        isAuthenticated: false,
        token: null,
        loadingToken: false
      }
    });
  }
};
const handleSignOut = (dispatch) => {
  dispatch({
    type: 'logoutUser'
  });
  logoutWithRedirect();
};

const handleInitHeader = async (dispatch) => {
  try {
    const token = await getAccessTokenSilently();

    if (token) {
      dispatch({
        type: 'loginUser',
        payload: {
          isAuthenticated: true,
          token,
          loadingToken: false
        }
      });
    }
    const user = await getIdTokenSilently();

    if (user) {
      // Identify the user with Common Room
      window.signals.identify({
        email: user.user_email,
        name: user.first_name + ' ' + user.last_name
      });
    }
  } catch (e) {
    dispatch({
      type: 'loginUser',
      payload: {
        isAuthenticated: false,
        token: null,
        loadingToken: false
      }
    });
  }
};

const UserContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    handleInitHeader(dispatch);
  }, [handleInitHeader]);

  return (
    <UserContext.Provider
      value={{
        state,
        actions: {
          handleSignOut: () => {
            handleSignOut(dispatch);
          },
          handleSignIn: () => {
            handleSignIn(dispatch);
          }
        }
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContextProvider, UserContext };

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.string
};
