import { getEnv } from './getEnv';
import { FORGE_AUTH_API, FORGE_CLIENT_ID, PKCE_ENV } from '../common/constants';
import { getEnvironmentConstant } from './getEnvironmentConstant';
import window from 'global/window';
import { jwtDecode } from 'jwt-decode';

let authClient;

const env = getEnv();

export const initializeClient = async () => {
  if (typeof window !== 'undefined') {
    try {
      const module = await import('@adsk/identity-web-sdk');
      const AuthClient = module.AuthClient;

      authClient = new AuthClient({
        client_id: getEnvironmentConstant({ env, key: FORGE_CLIENT_ID }),
        redirect_uri: `${getEnvironmentConstant({
          env,
          key: FORGE_AUTH_API
        })}`,
        redirect_uri_iframe: `${getEnvironmentConstant({
          env,
          key: FORGE_AUTH_API
        })}/auth`,
        env: getEnvironmentConstant({ env, key: PKCE_ENV }),
        scope: 'data:read',
        useRefreshTokens: true,
        cacheLocation: 'sessionstorage'
      });
      const token = await authClient.getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error('Error loading AuthClient:', error);
    }
  } else {
    console.error('AuthClient cannot be initialized in a non-browser environment.');
  }
};

(async () => {
  await initializeClient();
})();

export const loginWithRedirect = async () => {
  // setLoginRedirects(redirectUrl.pathname);
  await initializeClient();
  await authClient.loginWithRedirect({
    redirectTarget: window.parent.location,
    redirectMethod: 'replace'
  });
};

export const logoutWithRedirect = () => {
  initializeClient();
  try {
    authClient.logout({
      post_logout_redirect_uri: `${getEnvironmentConstant({
        env,
        key: FORGE_AUTH_API
      })}`
    });
  } catch (e) {
    console.log(e);
  }
};

export const getAccessTokenSilently = async () => {
  const token = await initializeClient();
  return token;
};

export const getIdTokenSilently = async () => {
  try {
    const idToken = await authClient.getIDTokenSilently();
    const decodedData = jwtDecode(idToken);
    return decodedData;
  } catch (e) {
    console.log(e);
  }
};

export const logout = () => {
  if (!authClient) {
    initializeClient();
  }
  try {
    authClient.logout();
  } catch (e) {
    console.log(e);
  }
};

export default {
  loginWithRedirect,
  logout,
  logoutWithRedirect,
  getAccessTokenSilently,
  getIdTokenSilently
};
